import {
  Button,
  Card,
  Checkbox,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
// import 'moment/locale/ar-sa';
import {
  fetchAllCategories,
  fetchCategorySubs,
} from '../../../actions/categories';
import {
  fetchAqarByAqarNumber,
  updateAqarInfor,
} from '../../../actions/aqarat';

import AccessForbidden from './AccessForbidden';
import AlertDialog from '../AlertDialog';
import HijriUtils from '@date-io/hijri';
import MuiAlert from '@material-ui/lab/Alert';
import Sidebar from '../Sidbar';
import Spinner from '../Spinner';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { printMalikInfo } from '../../../actions/printDocs';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const initialState = {
  noa_almilkia: '',
  melkia_okra: '',
  wasf_alaqar: '',
  halet_alaqar: '',
  kroki_msahi: '',
  raqam_almarhla: '',
  consultant_note: '',
  almsah_alijmalia_fi_alsak: '',
  molahthat: '',
  raqam_alsak: '',
  masdar_alsak: '',
  tarek_alheja: '',
  tarek_alsak: '',
  motnaza_alih: '',
  deedRegesterion: '',
  category: '',
  sub_category: '',
  tarek_alwkala: '',
  owe: '',
  AnqathLetter: '',
};
const ProprtyDetails = ({
  match,
  aqar: { loading, data },
  fetchAqarByAqarNumber,
  updateAqarInfor,
  history,
  printMalikInfo,
  auth: { user },
  categories: { categories, catLoading },
  subs: { subs, subsLoading },
  fetchAllCategories,
  fetchCategorySubs,
}) => {
  const { params } = match;
  const { id } = params;
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      textAlign: 'center',
      padding: '15px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    contentTitle: {
      margin: 'auto',
    },
    contenier: {
      padding: '5px',
    },
  });
  const classes = useStyles();
  const [formData, setFormData] = useState({
    noa_almilkia: '',
    melkia_okra: '',
    wasf_alaqar: '',
    halet_alaqar: '',
    kroki_msahi: '',
    consultant_note: '',
    almsah_alijmalia_fi_alsak: '',
    raqam_almarhla: '',
    molahthat: '',
    raqam_alsak: '',
    masdar_alsak: '',
    tarek_alheja: null,
    tarek_alsak: null,
    motnaza_alih: '',
    deedRegesterion: '',
    category: '',
    sub_category: '',
    tarek_alwkala: '',
    owe: '',
    AnqathLetter: '',
  });
  const {
    noa_almilkia,
    melkia_okra,
    wasf_alaqar,
    halet_alaqar,
    kroki_msahi,
    consultant_note,
    almsah_alijmalia_fi_alsak,
    raqam_almarhla,
    molahthat,
    raqam_alsak,
    masdar_alsak,
    tarek_alheja,
    tarek_alsak,
    motnaza_alih,
    deedRegesterion,
    category,
    sub_category,
    tarek_alwkala,
    AnqathLetter,
    owe,
  } = formData;
  const onChange = (event) => {
    const name = event.target.name;
    if (name === 'category') {
      fetchCategorySubs(event.target.value);
    }
    setFormData({ ...formData, [name]: event.target.value });
  };
  const onCheckboxChange = (event) => {
    const name = event.target.name;
    setFormData({ ...formData, [name]: +event.target.checked });
  };
  const handleTarekALhija = (date) => {
    setFormData({ ...formData, tarek_alheja: date });
  };
  const handleTarekALsak = (date) => {
    setFormData({ ...formData, tarek_alsak: date });
  };
  const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

  useEffect(() => {
    if (!data) fetchAqarByAqarNumber(id);
    if (!loading && data) {
      const aqarData = { ...initialState };
      for (const key in data) {
        if (key in aqarData) aqarData[key] = data[key];
      }
      setFormData(aqarData);
    }
  }, [loading, fetchAqarByAqarNumber, data, id]);
  useEffect(() => {
    if (categories.length === 0) {
      fetchAllCategories(1);
    }
  }, [loading]);
  const onlyNumbers = (e) => {
    if (e.target.value === '' || floatRegExp.test(e.target.value)) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      return false;
    }
  };
  const [edit, setEdit] = useState(true);
  const enableEdit = () => {
    setEdit(!edit);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(moment(tarek_alheja).format('YYYY-MM-DD HH:mm:ss'));
    if (tarek_alheja) {
      formData.tarek_alheja = moment(tarek_alheja).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }
    if (tarek_alsak) {
      formData.tarek_alsak = moment(tarek_alsak).format('YYYY-MM-DD HH:mm:ss');
    }
    updateAqarInfor(data.aqar_number, formData, history);
  };
  const catrgoriesOptions = categories.map((ar) => (
    <option key={ar.id} value={ar.id}>
      {ar.name}
    </option>
  ));
  const compaerTarekAlwakala = () => {
    const d1 = new Date();
    if (tarek_alwkala) {
      if (d1 >= new Date(tarek_alwkala)) {
        return true;
      }
    }
  };
  const handlPrintMalikInfo = () => {
    printMalikInfo(id);
  };
  if (
    user.department &&
    (user.roll.group_name === 'ادخال البيانات' ||
      user.roll.group_name === 'ادخال البيانات ٢' ||
      user.roll.group_name === 'الارشيف' ||
      user.roll.group_name === 'المهندسين')
  ) {
    return (
      <Fragment>
        {data === null || loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={4}>
            {data.molahthat ? (
              <AlertDialog molahthat={data.molahthat} isOpen={true} />
            ) : null}

            <Grid item sm={2}>
              <Sidebar proprtyId={id} />
            </Grid>
            <Grid item sm={10}>
              <Card className={classes.root}>
                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container className={classes.contenier}>
                    <Grid item xs={12}>
                      <Typography variant='h4' display='block'>
                        {data.aqar_number}{' '}
                        {motnaza_alih ? (
                          <Alert severity='error'>متنازع عليه</Alert>
                        ) : null}
                        {compaerTarekAlwakala() ? (
                          <Alert severity='error'>
                            يرجى مراجعة تاريخ الوكالة
                          </Alert>
                        ) : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>بيانات العقار</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>نطاق المرحلة</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        size='small'
                        margin='normal'
                        type='phone'
                        value={raqam_almarhla}
                        name='raqam_almarhla'
                        maxLength='10'
                        disabled={edit}
                        onChange={onChange}
                        label='نطاق المرحلة'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {user.roll.group_name ? (
                        user.roll.group_name === 'المهندسين' ? null : (
                          <Button
                            className={classes.butns}
                            // fullWidth
                            variant='contained'
                            color='primary'
                            onClick={enableEdit}
                          >
                            تحرير
                          </Button>
                        )
                      ) : null}
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>نوع الملكية</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <NativeSelect
                        value={noa_almilkia}
                        onChange={onChange}
                        fullWidth
                        disabled={edit}
                        name='noa_almilkia'
                        inputProps={{
                          id: 'noa_almilkia-menu',
                        }}
                      >
                        {noa_almilkia != '' ? (
                          noa_almilkia === 'ملك' ? (
                            <>
                              <option value='ملك'>صك</option>
                              <option value='وثيقة'>وثيقة</option>
                              {/* <onpption></onpption> */}
                            </>
                          ) : (
                            <>
                              <option value={noa_almilkia}>
                                {noa_almilkia}
                              </option>
                              <option value='ملك'>صك</option>
                              <option value='وثيقة'>وثيقة</option>
                            </>
                          )
                        ) : (
                          <>
                            <option></option>
                            <option value='ملك'>صك</option>
                            <option value='وثيقة'>وثيقة</option>
                          </>
                        )}
                        {/* <option value={noa_almilkia}>{noa_almilkia}</option> */}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>ملكية اخرى</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <NativeSelect
                        value={melkia_okra}
                        onChange={onChange}
                        fullWidth
                        disabled={edit}
                        name='melkia_okra'
                        inputProps={{
                          id: 'melkia_okra-menu',
                        }}
                      >
                        <option value=''></option>
                        <option value='بدون'>بدون</option>
                        <option value='وقف خاص'>وقف خاص</option>
                        <option value='وقف عام'>وقف عام</option>
                        <option value='حكر'>حكر</option>
                        <option value='طلب حجة استحكام'>طلب حجة استحكام</option>
                        <option value='أخرى'>أخرى</option>
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>التصنيف الرئيسي</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <NativeSelect
                        value={category}
                        onChange={onChange}
                        fullWidth
                        disabled={edit}
                        name='category'
                        inputProps={{
                          id: 'category-menu',
                        }}
                      >
                        <option></option>
                        {catrgoriesOptions}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>التصنيف الفرعي</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <NativeSelect
                        value={sub_category}
                        onChange={onChange}
                        fullWidth
                        disabled={edit}
                        name='sub_category'
                        inputProps={{
                          id: 'sub_category-menu',
                        }}
                      >
                        <option></option>
                        {subs
                          ? subs.map((ar) => (
                              <option key={ar.id} value={ar.id}>
                                {ar.name}
                              </option>
                            ))
                          : null}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>وصف العقار</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        title='name'
                        fullWidth
                        size='small'
                        margin='normal'
                        disabled={edit}
                        label='وصف العقار'
                        variant='outlined'
                        id='df'
                        value={wasf_alaqar}
                        onChange={onChange}
                        name='wasf_alaqar'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>ملاحظات</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        title='name'
                        fullWidth
                        size='small'
                        margin='normal'
                        label='ملاحظات'
                        disabled={edit}
                        variant='outlined'
                        value={molahthat}
                        onChange={onChange}
                        name='molahthat'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>
                        تاريخ تقديم طلب حجة الاستحكام
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <MuiPickersUtilsProvider
                        utils={HijriUtils}
                        locale='ar-SA'
                      >
                        <DatePicker
                          clearable
                          okLabel='موافق'
                          cancelLabel='الغاء'
                          fullWidth
                          disabled={edit}
                          clearLabel='مسح'
                          name='tarek_alheja'
                          labelFunc={(date) =>
                            date ? date.format('iYYYY/iMM/iDD') : ''
                          }
                          value={tarek_alheja}
                          onChange={handleTarekALhija}
                          minDate='1937-03-14'
                          maxDate='2076-11-26'
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {noa_almilkia === 'وثيقة' ? (
                      <Fragment>
                        <Grid item xs={12}>
                          <Typography variant='body1'>
                            بيانات الوثيقة
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>رقم الوثيقة </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled={edit}
                            // title="address"
                            margin='normal'
                            // type='phone'
                            value={raqam_alsak}
                            name='raqam_alsak'
                            type='tel'
                            maxLength='10'
                            onChange={onChange}
                            label='رقم الوثيقة'
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>تاريخ الوثيقة</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <MuiPickersUtilsProvider
                            utils={HijriUtils}
                            locale='ar-SA'
                          >
                            <DatePicker
                              clearable
                              disabled={edit}
                              okLabel='موافق'
                              cancelLabel='الغاء'
                              fullWidth
                              clearLabel='مسح'
                              name='tarek_alsak'
                              labelFunc={(date) =>
                                date ? date.format('iYYYY/iMM/iDD') : ''
                              }
                              value={tarek_alsak}
                              onChange={handleTarekALsak}
                              minDate='1937-03-14'
                              maxDate='2076-11-26'
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Grid item xs={12}>
                          <Typography variant='body1'>بيانات الصك</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>رقم الصك </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled={edit}
                            title='address'
                            margin='normal'
                            type='phone'
                            value={raqam_alsak}
                            name='raqam_alsak'
                            // type='tel'
                            maxLength='10'
                            onChange={onChange}
                            label='رقم الصك'
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>تاريخ الصك</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <MuiPickersUtilsProvider
                            utils={HijriUtils}
                            locale='ar-SA'
                          >
                            <DatePicker
                              clearable
                              okLabel='موافق'
                              cancelLabel='الغاء'
                              fullWidth
                              disabled={edit}
                              clearLabel='مسح'
                              name='tarek_alsak'
                              labelFunc={(date) =>
                                date ? date.format('iYYYY/iMM/iDD') : ''
                              }
                              value={tarek_alsak}
                              onChange={handleTarekALsak}
                              minDate='1937-03-14'
                              maxDate='2076-11-26'
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>حالة الصك</Typography>
                        </Grid>
                        <Grid item xs={10} style={{ display: 'flex' }}>
                          <TextField
                            fullWidth
                            size='small'
                            disabled={edit}
                            title='address'
                            margin='normal'
                            type='phone'
                            value={owe}
                            name='owe'
                            // type='tel'
                            onChange={onChange}
                            label='حالة الصك'
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>مصدر الصك</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.contentTitle}>
                          <NativeSelect
                            inputProps={{
                              id: 'masdar_alsak-menu',
                            }}
                            fullWidth
                            value={masdar_alsak}
                            name='masdar_alsak'
                            disabled={edit}
                            onChange={onChange}
                          >
                            {masdar_alsak ? (
                              <option value={masdar_alsak}>
                                {masdar_alsak}
                              </option>
                            ) : null}
                            <option></option>
                            <option value='المحكمة العامة'>
                              المحكمة العامة
                            </option>
                            <option value='كتابة عدل'>كتابة عدل</option>
                            <option value='اخرى'>اخرى</option>
                          </NativeSelect>
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            المساحة الاجمالية
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            title='address'
                            margin='normal'
                            disabled={edit}
                            type='phone'
                            // type='tel'
                            size='small'
                            maxLength='10'
                            name='almsah_alijmalia_fi_alsak'
                            value={almsah_alijmalia_fi_alsak}
                            onChange={onlyNumbers}
                            label='المساحة الاجمالية'
                            variant='outlined'
                          />
                        </Grid>
                      </Fragment>
                    )}

                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        حالة العقار من الاستشاري
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>حالة العقار</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size='small'
                        disabled={edit}
                        title='address'
                        margin='normal'
                        type='phone'
                        // type='tel'
                        value={halet_alaqar}
                        maxLength='10'
                        name='halet_alaqar'
                        onChange={onChange}
                        label='حالة العقار'
                        variant='outlined'
                      />
                    </Grid>

                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>ملاحظات الاستشاري</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size='small'
                        disabled={edit}
                        title='address'
                        margin='normal'
                        name='consultant_note'
                        onChange={onChange}
                        value={consultant_note}
                        label='ملاحظات الاستشاري'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.contentTitle}>
                      <Typography variant='body2'>يوجد كروكي مساحي </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox
                        checked={!!kroki_msahi}
                        color='primary'
                        disabled={edit}
                        onChange={onCheckboxChange}
                        name='kroki_msahi'
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </Grid>
                    {noa_almilkia === 'وثيقة' ? (
                      <Fragment>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            يوجد خطاب من العمدة
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Checkbox
                            color='primary'
                            disabled={edit}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </Grid>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>
                            نموذج اثبات تملك الانقاض
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Checkbox
                            checked={!!AnqathLetter}
                            name='AnqathLetter'
                            color='primary'
                            disabled={edit}
                            onChange={onCheckboxChange}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        </Grid>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Grid item xs={2} className={classes.contentTitle}>
                          <Typography variant='body2'>يوجد تسجيل صك</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Checkbox
                            color='primary'
                            disabled={edit}
                            name='deedRegesterion'
                            checked={!!deedRegesterion}
                            onChange={onCheckboxChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Fragment>
                    )}

                    <Grid item xs={4}>
                      <Button
                        type='submit'
                        fullWidth
                        disabled={edit}
                        variant='contained'
                        color='primary'
                      >
                        حفظ
                      </Button>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Button
                        className={classes.butns}
                        // disabled={edit}
                        fullWidth
                        onClick={handlPrintMalikInfo}
                        variant='contained'
                        color='primary'
                      >
                        طباعة معلومات المالك
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Card>
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  } else {
    return <AccessForbidden />;
  }
};
const mapStateToProps = (state) => ({
  aqar: state.aqar,
  auth: state.auth,
  categories: state.categories,
  subs: state.subs,
});
export default connect(mapStateToProps, {
  fetchAqarByAqarNumber,
  updateAqarInfor,
  printMalikInfo,
  fetchAllCategories,
  fetchCategorySubs,
})(ProprtyDetails);
